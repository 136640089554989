<template>
  <div>
    <vx-card class="w-full vx-row mb-base">
      <vs-checkbox v-model="CommingMeetings" style="margin-top: 70px">
        <span v-if="CommingMeetings">
          {{ $t("CommingMeetings") }}
        </span>
        <span v-else>
          {{ $t("History") }}
        </span>
      </vs-checkbox>
    </vx-card>
    <div class="w-full" id="Scroll">
      <vx-card v-if="CommingMeetings">
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            v-model="selected"
            pagination
            :max-items="itemsPerPage"
            search
            :data="Model"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <div class="flex flex-wrap-reverse items-center">
                <vs-button
                  type="border"
                  class="mb-4 md:w-auto float-right m-2"
                  @click="OpenNewRoomSidebar"
                  >{{ $t("Create Meeting") }}</vs-button
                >
              </div>
            </div>

            <template slot="thead">
              <vs-th sort-key="name">{{ $t("Name") }}</vs-th>
              <vs-th sort-key="Date">{{ $t("Date") }}</vs-th>
              <vs-th sort-key="ModeratorUrl">{{ $t("Moderator Url") }}</vs-th>
              <vs-th sort-key="AttendeeUrl">{{ $t("Patient Url") }}</vs-th>
              <vs-th sort-key="AgentUrl">{{ $t("Agent Url") }}</vs-th>

              <vs-th sort-key="CreatorName">{{ $t("Creator Name") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="name">{{ tr.name }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="Date">{{ tr.Date | formatDate("yyyy/MM/dd HH:mm:ss") }}</p>
                  </vs-td>
                  <vs-td>

                    <a
                     v-if="CanJoinMeeting(tr)"
                         @click.stop="CopyMeeting(tr.ModeratorUrl,tr.ReservationId,1)"
                    >
                      <span style="color: green"> Copy link</span>
                    </a>
                  </vs-td>
                  <vs-td>
                    <a
                        v-if="CanJoinMeeting(tr)"
                       @click.stop="CopyMeeting(tr.AttendeeUrl,tr.ReservationId,2)"
                    >
                      <span style="color: green"> Copy link</span>
                    </a>
                  </vs-td>
                  <vs-td>
                    <a
                      v-if="CanJoinMeeting(tr)"
                      @click.stop="openMeeting(tr.AgentUrl,tr.ReservationId)"
                    >
                      <span style="color: green"> Join</span>
                    </a>
                  </vs-td>
                  <vs-td>
                    <p class="CreatorName">{{ tr.CreatorName }}</p>
                  </vs-td>

                  <!-- <vs-td class="whitespace-no-wrap">
                    <feather-icon
                      @click="InviteMeeting(tr)"
                      icon="PlusIcon"
                      class="hover:text-primary text-dark"
                      svgClasses="h-8 w-8"
                    />
                  </vs-td> -->
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
        <vs-button type="border" @click="$router.go(-1)">Back</vs-button>
      </vx-card>
      <vx-card v-if="!CommingMeetings">
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            v-model="selected"
            pagination
            :max-items="itemsPerPage"
            search
            :data="ModelHistory"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            ></div>

            <template slot="thead">
              <vs-th sort-key="name">{{ $t("Name") }}</vs-th>
              <vs-th sort-key="Date">{{ $t("Date") }}</vs-th>
              <vs-th sort-key="CreatorName">{{ $t("CreatorName") }}</vs-th>
              <vs-th sort-key="HasRecord">{{ $t("HasRecord") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="name">{{ tr.name }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="Date">{{ tr.Date | formatDate("yyyy/MM/dd HH:mm:ss") }}</p>
                  </vs-td>

                  <vs-td>
                    <p class="CreatorName">{{ tr.CreatorName }}</p>
                  </vs-td>

                  <vs-td class="whitespace-no-wrap">
                    <feather-icon
                      v-if="tr.HasRecord ==true"
                      @click="GetMeetingRecords(tr)"
                      icon="DiscIcon"
                      class="hover:text-primary text-dark"
                      svgClasses="h-8 w-8"
                    />
                    <span v-else>No Record</span>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
        <vs-button type="border" @click="$router.go(-1)">Back</vs-button>
      </vx-card>

      <vs-popup
       title="Records"
      :active.sync="isRecord"
      v-if="isRecord">
<div class="vx-row w-full" v-if="Records.length > 0">
          <!-- <div
            v-for="(item, index) in Records"
            :key="index"
            class="relative mb-base rounded-lg justify-center h-32 w-32 FixedHeight"
            :style="{
              backgroundImage: 'url(' + item.playbacks[0].previewImages[0].url + ')',
            }"
          > -->
           <div
            v-for="(item, index) in Records"
            :key="index"
            class="relative mb-base rounded-lg justify-center h-32 w-32 FixedHeight"

          >
            <div
              class="vx-col justify-center rounded-lg absolute inset-0 h-32 w-32 bg-opacity-100"
            >
              <feather-icon
                @click="PlayRecord(item.playbacks[0].url)"
                icon="PlayIcon"
                svgClasses="h-24 w-24 text-success mt-4 ml-4"
              />
            </div>
          </div>
        </div>
      </vs-popup>

    </div>
    <MeetingSidebar
      :isSidebarActive="isRoomSidebar"
      @closeSidebar="toggleMeetingSidebar"
      :MeetingDetails="MeetingDetails"
    />
  </div>
</template>

<script>
import MeetingSidebar from "@/views/LiveSession/MeetingSidebar.vue";
export default {
  data() {
    return {
      domain: this.$http.defaults.baseURL,
      IsActive: true,
      selected: [],
      Model: [],
      Records: [],
      isRecord : false,
      CommingMeetings: true,
      ModelHistory: [],
      isRoomSidebar: false,
      MeetingDetails: {},
      itemsPerPage: 5,
      Id: null,
    };
  },
  components: { MeetingSidebar },
  methods: {
    CanJoinMeeting(meeting) {
      let DateStart = new Date(meeting.Date);
      let DateNow = new Date();
       debugger;
      const milliseconds = DateStart - DateNow;

      var Minutes = Math.floor(milliseconds / 60000);
       if (Minutes)
         return true;
        else
         return false;
    },
    GetMeetingRecords(tr) {
      this.isRecord = true;
      debugger
      this.$vs.loading();
      var vm = this;
      this.$store
        .dispatch("auth/GetRecordings", tr.meetingId)
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
            vm.Records = res.data.recordings;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
    },
    OpenRoomSidebar(item) {
      debugger;
      this.MeetingDetails = item;

      this.toggleMeetingSidebar(true);
    },
    OpenNewRoomSidebar() {
      debugger;
      this.MeetingDetails = {};

      this.toggleMeetingSidebar(true);
    },
    toggleMeetingSidebar(val = false) {
      if (val == false) {
        this.GetMeetingsList();
      }
      this.isRoomSidebar = val;
    },
    GetMeetingsList() {
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("auth/GetUserMeetingsList")
        .then((res) => {
          if (res.status == 200) {
            if (res.data.length > 0) {
              debugger;
              this.Model = res.data;
            } else {
              window.showNoRecords();
            }
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
    },
    GetUserMeetingsHistoryList() {
      this.$vs.loading();
      this.$store
        .dispatch("auth/GetUserMeetingsHistoryList")
        .then((res) => {
          if (res.status == 200) {
            if (res.data.length > 0) {
              this.ModelHistory = res.data;
            }
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
    },
    PlayRecord(url) {
      window.open(url, "_blank");
    },
    copyLink(url)
    {
            navigator.clipboard.writeText(url);
              this.$vs.notify({
              color: "success",
              position: "right-bottom",
              text: "link copy successfully",
            });
    },
    createSeeionMeeting(sesstionId,type)
    {
          this.$store
        .dispatch("auth/CreateMeetingSession", sesstionId)
        .then((res) => {
          if (res.status == 200) {

              if(type==1)
                this.copyLink(res.data.Data.ModeratorUrl);
               if(type==2)
                this.copyLink(res.data.Data.AttendeeUrl);
               if(type==3)
                 window.open(res.data.Data.AttendeeUrl , "_blank");
          }
        })
    },
    CopyMeeting(url,sesstionId,type)
    {
      debugger
        if(url)
        {
            this.copyLink(url);
        }
        else if(sesstionId)
        {
            this. createSeeionMeeting(sesstionId,type);
        }
    },
    openMeeting(url,sesstionId)
    {
      if(url)
          window.open(url , "_blank");

      else if(sesstionId)
      {
            this. createSeeionMeeting(sesstionId,3);
      }
    },
    JoinMeeting(tr) {
      debugger;
      var password = tr.moderatorPW;

      this.$vs.loading();
      var Obj = {
        meetingID: tr.meetingId,
        password: password,
      };
      this.$store
        .dispatch("auth/JoinMeeting", Obj)
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();

            window.open(res.data, "_blank");
          }
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
    },
    onCopy() {
      this.$vs.notify({
        title: "Success",
        text: "Text copied successfully",
        color: "success",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-check-circle",
      });
    },
    InviteMeeting(tr) {
      var getUrl = window.location;
      var baseUrl = getUrl.protocol + "//" + getUrl.host;
      var URL = "";
      URL = `${baseUrl}/JoinMeeting/${tr.meetingId}/${tr.attendeePW}`;
      const thisIns = this;

      this.$copyText(URL).then(
        function () {
          thisIns.$vs.notify({
            title: "Success",
            text: "Text copied successfully",
            color: "success",
            iconPack: "feather",
            position: "top-center",
            icon: "icon-check-circle",
          });
        },
        function () {
          thisIns.$vs.notify({
            title: "Failed",
            text: "Error in copying text",
            color: "danger",
            iconPack: "feather",
            position: "top-center",
            icon: "icon-alert-circle",
          });
        }
      );
    },
  },
  created() {
    this.GetMeetingsList();
    this.GetUserMeetingsHistoryList();
  },
};
</script>
<style lang="scss">
.FixedHeight {
  background-size: cover;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
